import { useAuthStore } from "@/stores/auth.store";

export const AuthGuard = async (to, from, next) => {
  if (!localStorage.getItem("access_token")) {
    next({ name: "Login" });
    return;
  }

  const useAuth = useAuthStore();

  await useAuth.getProfile();

  next();
};
