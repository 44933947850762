import { defineStore } from "pinia";
import ApiService from "@/services/api.service";

export const useAuthStore = defineStore("AuthStore", {
  // State
  state: () => ({
    profile: "",
  }),

  // Getters
  getters: {},

  // Actions
  actions: {
    async getProfile() {
      try {
        const res = await ApiService.get("/me");
        if (res && res.data.status === 200) {
          this.profile = res.data.data;
        }

        return res;
      } catch (err) {
        console.log(err);
      }
    },
  },
});
