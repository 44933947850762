import { createRouter, createWebHistory } from "vue-router";
import AuthRoute, { lazyLoadRoute } from "@/router/modules/auth.route";
import UserRoute from "@/router/modules/user.route";
import TeamRoute from "@/router/modules/team.route";
import InformationProfileRoute from "@/router/modules/information-profile.route";
import HistoryRoute from "@/router/modules/history.route";
import SearchRoute from "@/router/modules/search.route";
import SearchAccRoute from "@/router/modules/search-acc.route";

export const lazyLoadLayout = (pageName) => {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/layouts/${pageName}`);
};

const routes = [
  // Auth
  {
    path: "/auth",
    name: "Auth",
    redirect: { name: "Login" },
    component: lazyLoadLayout("LoginLayout.vue"),
    children: [...AuthRoute],
  },

  // Main
  {
    path: "/",
    redirect: {
      name: "InformationProfileList",
    },
    name: "Index",
    component: lazyLoadLayout("MainLayout.vue"),
    children: [
      ...SearchRoute,
      ...SearchAccRoute,
      ...UserRoute,
      ...TeamRoute,
      ...InformationProfileRoute,
      ...HistoryRoute,
    ],
  },

  // Auth
  {
    path: "/error",
    component: lazyLoadLayout("LoginLayout.vue"),
    children: [
      {
        path: "/error/403",
        name: "Error403",
        component: lazyLoadRoute("error/Error403Page.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_ROUTE),
  linkActiveClass: "active",
  routes,
});

export default router;
