import {
  CaretRightOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  HistoryOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MonitorOutlined,
  RollbackOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";

export const setupAntDesignIcon = (app) => {
  app.component("UserOutlined", UserOutlined);
  app.component("TeamOutlined", TeamOutlined);
  app.component("InfoCircleOutlined", InfoCircleOutlined);
  app.component("HistoryOutlined", HistoryOutlined);
  app.component("EditOutlined", EditOutlined);
  app.component("DeleteOutlined", DeleteOutlined);
  app.component("EyeOutlined", EyeOutlined);
  app.component("CopyOutlined", CopyOutlined);
  app.component("CaretRightOutlined", CaretRightOutlined);
  app.component("CheckCircleOutlined", CheckCircleOutlined);
  app.component("RollbackOutlined", RollbackOutlined);
  app.component("DownOutlined", DownOutlined);
  app.component("MenuFoldOutlined", MenuFoldOutlined);
  app.component("MenuUnfoldOutlined", MenuUnfoldOutlined);
  app.component("CaretRightOutlined", CaretRightOutlined);
  app.component("MonitorOutlined", MonitorOutlined);
  app.component("InboxOutlined", InboxOutlined);
  app.component("DownloadOutlined", DownloadOutlined);
};
