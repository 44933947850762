import { ResolveGuard, AuthGuard } from "@/core/guards";
import { PermissionGuard } from "@/core/guards/permission.guard";

export const lazyLoadRoute = (pageName) => {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/pages/${pageName}`);
};

export default [
  {
    path: "/search-acc",
    beforeEnter: ResolveGuard([AuthGuard]),
    children: [
      {
        path: "",
        name: "SearchAccList",
        component: lazyLoadRoute("search-acc/InformationProfileIndex.vue"),
        beforeEnter: ResolveGuard([PermissionGuard]),
        meta: {
          permissions: ["ADMIN", "STAFF"],
        },
      },
    ],
  },
];
