import axios from "axios";
import { successRequestInterceptor } from "@/core/interceptors/success-request-interceptor";
import { errorRequestInterceptor } from "@/core/interceptors/error-request-interceptor";
import { successResponseInterceptor } from "@/core/interceptors/success-response-interceptor";
import { errorResponseInterceptor } from "@/core/interceptors/error-response-interceptor";

const config = {
  baseURL: process.env.VUE_APP_BASE_URL,
};

export const instance = axios.create(config);

// set request global
instance.interceptors.request.use(
  successRequestInterceptor,
  errorRequestInterceptor
);

// set response global
instance.interceptors.response.use(
  successResponseInterceptor,
  errorResponseInterceptor
);
