import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { setupPinia } from "@/plugins/pinia";
import setupAntDesign from "@/plugins/ant-design";
import { setupAntDesignIcon } from "@/plugins/ant-design-icon";

const app = createApp(App);

// Configure Pinia
setupPinia(app);

// Configure Ant Design
setupAntDesign(app);

// Configure Ant Design Icon
setupAntDesignIcon(app);

app.use(router);

app.mount("#app");
