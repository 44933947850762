import { ResolveGuard, AuthGuard } from "@/core/guards";

export const lazyLoadRoute = (pageName) => {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/pages/${pageName}`);
};

export default [
  {
    path: "/search",
    beforeEnter: ResolveGuard([AuthGuard]),
    children: [
      {
        path: "",
        name: "SearchList",
        component: lazyLoadRoute("search/InformationProfileIndex.vue"),
      },
      {
        path: "create",
        name: "InformationProfileCreate",
        component: lazyLoadRoute(
          "information-profile/InformationProfileCreate.vue"
        ),
      },
      {
        path: "edit/:id",
        name: "InformationProfileEdit",
        component: lazyLoadRoute(
          "information-profile/InformationProfileEdit.vue"
        ),
      },
      {
        path: "edit/:id/new-gmail",
        name: "InformationProfileNewGmail",
        component: lazyLoadRoute(
          "information-profile/InformationProfileNewGmail.vue"
        ),
      },
    ],
  },
];
