import { get } from "lodash-es";
import {
  HTTP_STATUS_BAD_REQUEST,
  HTTP_STATUS_SERVICE_UNAVAILABLE,
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_NOT_FOUND,
} from "@/core/interceptors/http-status";
import {
  badRequestInterceptor,
  overloadInterceptor,
  unauthorizedInterceptor,
  notFoundInterceptor,
} from "@/core/interceptors/response-interceptors";

export const errorResponseInterceptor = (error) => {
  if (error) {
    // No Internet
    if (!get(error, "response.status")) {
      return new Promise(() => {});
    }

    const {
      response: { status },
    } = error;

    if (status === HTTP_STATUS_BAD_REQUEST) {
      return badRequestInterceptor(error);
    }
    if (status === HTTP_STATUS_UNAUTHORIZED) {
      return unauthorizedInterceptor(error);
    }
    if (status === HTTP_STATUS_SERVICE_UNAVAILABLE) {
      return overloadInterceptor(error);
    }
    if (status === HTTP_STATUS_NOT_FOUND) {
      return notFoundInterceptor(error);
    }

    return Promise.reject(error);
  }

  return Promise.reject(error);
};
