import "ant-design-vue/dist/antd.css";
import Antd, { message, notification } from "ant-design-vue";

export function setupAntDesign(app) {
  app.use(Antd);

  // Config global notification
  app.config.globalProperties.$notification = notification;
  app.config.globalProperties.$message = message;
}

export default setupAntDesign;
