import { ResolveGuard, AuthGuard } from "@/core/guards";
import { PermissionGuard } from "@/core/guards/permission.guard";
import { OtpGuard } from "@/core/guards/otp.guard";

export const lazyLoadRoute = (pageName) => {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/pages/${pageName}`);
};

export default [
  {
    path: "/history",
    beforeEnter: ResolveGuard([AuthGuard]),
    children: [
      {
        path: "/history/information-profile",
        name: "HistoryInformationProfileList",
        component: lazyLoadRoute("history/HistoryInformationProfileIndex.vue"),
        beforeEnter: ResolveGuard([PermissionGuard, OtpGuard]),
        meta: {
          permissions: ["ADMIN"],
        },
      },
      {
        path: "/history/account",
        name: "HistoryAccountList",
        component: lazyLoadRoute("history/HistoryAccountIndex.vue"),
        beforeEnter: ResolveGuard([PermissionGuard]),
        meta: {
          permissions: ["ADMIN"],
        },
      },
    ],
  },
];
