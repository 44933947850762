import { LoginGuard, ResolveGuard } from "@/core/guards";

export const lazyLoadRoute = (pageName) => {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/pages/${pageName}`);
};

export default [
  {
    path: "/auth/login",
    name: "Login",
    component: lazyLoadRoute("auth/LoginPage.vue"),
    beforeEnter: ResolveGuard([LoginGuard]),
  },
];
