import { ResolveGuard, AuthGuard } from "@/core/guards";
import { PermissionGuard } from "@/core/guards/permission.guard";

export const lazyLoadRoute = (pageName) => {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/pages/${pageName}`);
};

export default [
  {
    path: "/user",
    beforeEnter: ResolveGuard([AuthGuard]),
    children: [
      {
        path: "",
        name: "UserList",
        component: lazyLoadRoute("user/UserIndex.vue"),
        beforeEnter: ResolveGuard([PermissionGuard]),
        meta: {
          permissions: ["ADMIN"],
        },
      },
      {
        path: "create",
        name: "UserCreate",
        component: lazyLoadRoute("user/UserCreate.vue"),
        beforeEnter: ResolveGuard([PermissionGuard]),
        meta: {
          permissions: ["ADMIN"],
        },
      },
      {
        path: "edit/:id",
        name: "UserEdit",
        component: lazyLoadRoute("user/UserEdit.vue"),
        beforeEnter: ResolveGuard([PermissionGuard]),
        meta: {
          permissions: ["ADMIN"],
        },
      },
    ],
  },
];
