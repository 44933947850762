import { useAuthStore } from "@/stores/auth.store";

export const PermissionGuard = async (to, from, next) => {
  const useAuth = useAuthStore();
  const allowAccess = to.meta.permissions.includes(
    useAuth.profile.role.role_name
  );

  if (!allowAccess) {
    return next({ name: "Error403" });
  }

  next();
};
