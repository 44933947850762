import { instance } from "@/core/interceptors";

const ApiService = {
  request(config = {}) {
    return instance.request(config);
  },

  get(url, config = {}) {
    return instance.get(`${url}`, config);
  },

  delete(url, config = {}) {
    return instance.delete(`${url}`, config);
  },

  head(url, config = {}) {
    return instance.head(`${url}`, config);
  },

  options(url, config = {}) {
    return instance.options(`${url}`, config);
  },

  post(url, data, config = {}) {
    return instance.post(`${url}`, data, config);
  },

  uploadFile(url, file) {
    return instance.post(url, file, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  put(url, data, config = {}) {
    return instance.put(`${url}`, data, config);
  },

  patch(url, data, config = {}) {
    return instance.patch(`${url}`, data, config);
  },
};

export default ApiService;
