import { ResolveGuard, AuthGuard } from "@/core/guards";

export const lazyLoadRoute = (pageName) => {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/pages/${pageName}`);
};

export default [
  {
    path: "/information-profile",
    beforeEnter: ResolveGuard([AuthGuard]),
    children: [
      {
        path: "",
        name: "InformationProfileList",
        component: lazyLoadRoute(
          "information-profile/InformationProfileIndex.vue"
        ),
      },
      {
        path: "create",
        name: "InformationProfileCreate",
        component: lazyLoadRoute(
          "information-profile/InformationProfileCreate.vue"
        ),
      },
      {
        path: "edit/:id",
        name: "InformationProfileEdit",
        component: lazyLoadRoute(
          "information-profile/InformationProfileEdit.vue"
        ),
      },
      {
        path: "edit/:id/new-gmail",
        name: "InformationProfileNewGmail",
        component: lazyLoadRoute(
          "information-profile/InformationProfileNewGmail.vue"
        ),
      },
      {
        path: "edit/:id/upload-images",
        name: "InformationProfileUploadImage",
        component: lazyLoadRoute(
          "information-profile/InformationProfileUploadImage.vue"
        ),
      },
    ],
  },
];
